import React, { useState } from 'react';
import ReactMapGL from 'react-map-gl';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        marginLeft: "355px",
        padding: '0',
        "& img": {
            width: "100%",
        }
    }
}));

const Projection = (props) => {

    const classes = useStyles();
    const [ viewport, setViewport ] = useState({
        width: 400,
        height: 400,
        latitude: 37,
        longitude: -122,
        zoom: 8
    });


    return (
	    <div className={classes.root} >
            <img src="/images/map.png" alt="Map of Kenya" />
	    </div>
    );

}

export default Projection; // can't call it a Map beacuse that's a reserved word in JavaScript :)
